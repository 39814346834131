import { DeviceAudio } from '@bussiness/store/features/device/device.reducer';
import { PlcSound } from '@components/models/sound.models';

export const BAGEL_PHASE_AUDIOS: DeviceAudio<PlcSound>[] = [
	{
		id: 'hit',
		src: 'assets/sounds/hit.wav',
	},
	{
		id: 'miss',
		src: 'assets/sounds/miss.wav',
	},
	{
		id: 'cheer',
		src: 'assets/sounds/cheer.wav',
	},
	{
		id: 'large-cheer',
		src: 'assets/sounds/large-cheer.wav',
	},
	{
		id: 'oooh',
		src: 'assets/sounds/oooh.wav',
	},
	{
		id: 'oooh-cheer',
		src: 'assets/sounds/oooh-cheer.wav',
	},
	{
		id: 'time-over',
		src: 'assets/sounds/time-over.wav',
	},
	{
		id: 'digit',
		src: 'assets/sounds/digit.wav',
	},
	{
		id: 'digit-end',
		src: 'assets/sounds/digit-end.wav',
	},
	{
		id: 'loser',
		src: 'assets/sounds/loser.wav',
	},
	{
		id: 'full-bagel',
		src: 'assets/sounds/full-bagel.wav',
	},
];

export const CHEER_HITS = 3;
export const LARGE_CHEER_HITS = 5;
